import { EInvoiceStatus } from '~/graphql/types'
import { Container } from './styles'
import { CONTRAC_INVOICE, theme, translateContractInvoice, trimString } from '~/utils'
import { useMediaQuery } from '@mui/material'

type FinancialStatusProps = {
  status: EInvoiceStatus
}
export const FinancialStatus: React.FC<FinancialStatusProps> = ({ status }) => {
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Container status={status}>
      <span>{trimString(translateContractInvoice(status, CONTRAC_INVOICE), { maxTextLength: isLowerSm ? 3 : 20, textCutLength: isLowerSm ? 3 : 20 })}</span>
      {/* <span>{translateContractInvoice(status, CONTRAC_INVOICE)}</span> */}
    </Container>
  )
}
